<template>
  <div
    style="width:100%;height:100%;position: relative;"
    v-loading="loading"
    element-loading-text="数据量过大，加载中..."
  >
    <div id="maps"></div>
    <div class="headers">
      <el-button type="primary" @click="goback">返回</el-button>
      <span class="font-w-b">请选择时间段:</span>
      <el-date-picker
        :editable="false"
        style="margin-left: -10px"
        v-model="searchTime"
        type="datetimerange"
        :start-placeholder="searchTime.startTime"
        :end-placeholder="searchTime.endTime"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-button type="primary" @click="search">搜索</el-button>
      <el-button type="primary" @click="start">开始</el-button>
      <el-button type="primary" @click="pause">暂停</el-button>
      <el-button type="primary" @click="stop">停止</el-button>

      <!-- <el-button class="electronic" @click="goElectronic">电子围栏</el-button> -->
    </div>
    <!-- 弹框 -->
    <div class="MessageBox">
      <ul>
        <li>
          <span>设备名称：</span><span>{{ storageName }}</span>
        </li>
        <li>
          <span>设备序列号：</span>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="deviceAddress"
          >
            <span slot="reference" class="deviceAddress ">{{
              deviceAddress
            }}</span>
          </el-popover>
        </li>
        <li>
          <span>设备速度：</span><span>{{ newList.speed }}km/h</span>
        </li>
        <li>
          <span>设备方向：</span><span>{{ newList.direction }}</span>
        </li>
        <li>
          <span>设备状态：</span><span>{{ newList.isRun }}</span>
        </li>
        <li>
          <span>定位方式：</span><span>{{ newList.locationType }}</span>
        </li>
        <li>
          <span>记录时间：</span><span>{{ newList.dataTime }}</span>
        </li>
        <li>
          <span>最后记录时间：</span><span>{{ maxTime }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getStorageTrajectory } from "../../api/FacilityBack";
import { parseTime } from "../../utils/methods";
let timer;
let index = 0;
let map;
export default {
  data() {
    return {
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      path: [],
      pathList: [],
      speed: 200, //速度
      lushu: {}, //路书
      mycaiId: "",
      myMap: {}, //地图
      deviceAddress: "", //设备地址码
      searchTime: [], // 时间选择器
      center: {}, //中心点位置
      startBtn: false,
      pauseBtn: false,
      stopBtn: true,
      show: true,
      newList: {
        speed: 0,
        direction: "",
        isRun: "",
        locationType: "",
        dataTime: ""
      },
      maxTime: "" // 最后时间
    };
  },
  created() {
    this.deviceAddress = this.$route.query.deviceAddress;
    this.storageName = this.$route.query.storageName;
    this.getList();
  },
  mounted() {
    map = new BMap.Map("maps");
  },
  watch: {
    newList: {
      deep: true,
      handler: function(newVal, oldVal) {
        this.newList = newVal;
      }
    }
  },
  methods: {
    auto(i, l, o) {
      return new Promise((res, reject) => {
        // 驾车导航
        const driving = new BMap.DrivingRoute(map, {
          onSearchComplete: function(results) {
            if (driving.getStatus() == BMAP_STATUS_SUCCESS) {
              const plan = driving.getResults().getPlan(0);
              const num = plan.getNumRoutes();
              for (let j = 0; j < num; j++) {
                const pts = plan.getRoute(j).getPath(); //通过驾车实例，获得一系列点的数组
                if (o) {
                  pts[0] = {
                    ...pts[0],
                    o // 行动参数
                  };
                }
                for (let i = 0; i < pts.length; i += 1) {
                  pts[i] = {
                    ...pts[i],
                    v: true // 是否为处理过的点
                  };
                }
                res(pts);
              }
            }
          }
        });
        driving.search(i, l);
      });
    },
    async init() {
      map.clearOverlays();
      const { lng, lat } = this.center;
      const point = new BMap.Point(lng, lat);
      map.centerAndZoom(point, 11);
      map.enableScrollWheelZoom(true);
      let points = [];
      let polyLines = [];
      for (let i = 0; i < this.path.length; i += 1) {
        polyLines.push(new BMap.Point(this.path[i].lng, this.path[i].lat));
        points.push({
          ...new BMap.Point(this.path[i].lng, this.path[i].lat),
          o: {
            direction: this.path[i].direction,
            deviceAddress: this.path[i].deviceAddress,
            speed: this.path[i].speed,
            storageName: this.path[i].storageName,
            dataTime: this.path[i].dataTime,
            locationType: this.path[i].locationType,
            isRun: this.path[i].isRun
          }
        });
      }
      const polyLine = new BMap.Polyline(polyLines, {
        enableEditing: false,
        strokeColor: "red", //设置颜色
        strokeWeight: 5 //宽度
      });
      map.addOverlay(polyLine);
      this.initLushu(map, points, this.mycaiId);
      this.loading = false;
    },
    initLushu(map, points, mycaiId) {
      this.lushu = new BMapLib.LuShu(map, points, {
        defaultContent: mycaiId, //"从天安门到百度大厦"
        autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
        icon: new BMap.Icon(
          "https://qiniu1.xuruidea.com/27/20201225/green1608882423166.png",
          new BMap.Size(52, 45),
          {
            // 左右 上下偏移量
            anchor: new BMap.Size(20, 44)
          }
        ),
        speed: this.speed,
        // enableRotation: true, //是否设置marker随着道路的走向进行旋转
        landmarkPois: [
          {
            lng: 116.306954,
            lat: 40.05718,
            html: "加油站",
            pauseTime: 2
          }
        ]
      });
      var that = this;
      //   出现轨迹
      BMapLib.LuShu.prototype._move = async function(
        initPos,
        targetPos,
        effect
      ) {
        let me = this;
        const pointLevel = new BMap.Point(
          me._path[me.i].lng,
          me._path[me.i].lat
        );
        if (initPos.lng == me._path[0].lng && initPos.lat == me._path[0].lat) {
          me._marker.disableMassClear();
          map.clearOverlays();
          me._marker.enableMassClear();
        }
        map.setCenter(pointLevel);
        if (me._path[me.i].o) {
          that.newList = { ...me._path[me.i].o };
          if (that.newList.isRun == "0") {
            that.newList.isRun = "停止";
          } else {
            that.newList.isRun = "运动";
          }
          if (that.newList.locationType == "0") {
            that.newList.locationType = "wifi定位";
          } else {
            that.newList.locationType = "gps定位";
          }
        }
        if (!me._path[me.i].v || !me._path[me.i + 1].v) {
          let o = undefined;
          if (me._path[me.i].o) {
            o = { ...me._path[me.i].o };
            that.newList = { ...me._path[me.i].o };
            if (that.newList.isRun == "0") {
              that.newList.isRun = "停止";
            } else {
              that.newList.isRun = "运动";
            }
            if (that.newList.locationType == "0") {
              that.newList.locationType = "wifi定位";
            } else {
              that.newList.locationType = "gps定位";
            }
          } else if (me._path[me.i + 1].o) {
            o = { ...me._path[me.i + 1].o };
            that.newList = { ...me._path[me.i + 1].o };
            if (that.newList.isRun == "0") {
              that.newList.isRun = "停止";
            } else {
              that.newList.isRun = "运动";
            }
            if (that.newList.locationType == "0") {
              that.newList.locationType = "wifi定位";
            } else {
              that.newList.locationType = "gps定位";
            }
          }
          const pointsArr = await that.auto(
            new BMap.Point(me._path[me.i].lng, me._path[me.i].lat),
            new BMap.Point(me._path[me.i + 1].lng, me._path[me.i + 1].lat),
            o
          );
          me._path.splice(me.i, 2);
          for (const i of pointsArr.reverse()) {
            me._path.splice(me.i, 0, i);
          }
        }
        const pointA = new BMap.Point(me._path[me.i].lng, me._path[me.i].lat);
        const pointB = new BMap.Point(
          me._path[me.i + 1].lng,
          me._path[me.i + 1].lat
        );
        const polyline = new BMap.Polyline([pointA, pointB], {
          enableEditing: false,
          strokeColor: "red", //设置颜色
          strokeWeight: 5 //宽度
        });
        map.addOverlay(polyline);
        //当前的帧数
        let currentCount = 0,
          //步长，
          timer = 10,
          // 米/秒
          step = this._opts.speed / (1000 / timer),
          //初始坐标
          init_pos = this._projection.lngLatToPoint(this._path[me.i]),
          //获取结束点的(x,y)坐标
          target_pos = this._projection.lngLatToPoint(this._path[me.i + 1]),
          //总的步长
          count = Math.round(me._getDistance(init_pos, target_pos) / step);
        // const pointsArr = await that.auto(initPos, targetPos); //点数组
        // console.log(pointsArr)
        // let me = this,
        //   //当前的帧数
        //   currentCount = 0,
        //   //步长，
        //   timer = 10,
        //   // 米/秒
        //   step = this._opts.speed / (1000 / timer),
        //   //初始坐标
        //   init_pos = this._projection.lngLatToPoint(initPos),
        //   //获取结束点的(x,y)坐标
        //   target_pos = this._projection.lngLatToPoint(targetPos),
        //   //总的步长
        //   count = Math.round(me._getDistance(init_pos, target_pos) / step);
        if (count < 1) {
          me._moveNext(++me.i);
          return;
        }
        me._intervalFlag = setInterval(function() {
          //两点之间当前帧数大于总帧数的时候，则说明已经完成移动
          if (currentCount >= count) {
            clearInterval(me._intervalFlag);
            //移动的点已经超过总的长度
            if (me.i > me._path.length) {
              return;
            }
            //运行下一个点
            me._moveNext(++me.i);
          } else {
            currentCount++;
            var x = effect(init_pos.x, target_pos.x, currentCount, count),
              y = effect(init_pos.y, target_pos.y, currentCount, count),
              pos = me._projection.pointToLngLat(new BMap.Pixel(x, y));
            //设置marker
            if (currentCount == 1) {
              var proPos = null;
              if (me.i - 1 >= 0) {
                proPos = me._path[me.i - 1];
              }
              if (me._opts.enableRotation == true) {
                me.setRotation(proPos, initPos, targetPos);
              }
              if (me._opts.autoView) {
                if (!me._map.getBounds().containsPoint(pos)) {
                  me._map.setCenter(pos);
                }
              }
            }
            //正在移动
            me._marker.setPosition(pos);
            //设置自定义overlay的位置
            me._setInfoWin(pos);
          }
        }, timer);
      };
    },
    // 开始按钮
    async start() {
      this.lushu.start();
    },
    // 暂停按钮
    pause() {
      this.lushu.pause();
    },
    stop() {
      this.lushu.stop();
    },

    getList() {
      this.loading = true;
      this.center.lng = this.$route.query.lng;
      this.center.lat = this.$route.query.lat;
      var myDate = new Date();
      this.searchTime.push(parseTime(myDate));
      var oneDay = 24 * 60 * 60 * 1000;
      var yesDate = myDate.setTime(myDate.getTime() - oneDay);
      this.searchTime.unshift(parseTime(yesDate));
      getStorageTrajectory({
        deviceAddress: this.deviceAddress,
        startTime: this.searchTime[0],
        endTime: this.searchTime[1]
      }).then(res => {
        if (res.data.storageDataVos.length <= 1) {
          this.center.lng = this.$route.query.lng;
          this.center.lat = this.$route.query.lat;
          let { lng, lat } = this.center;
          var map = new BMap.Map("maps");
          var point = new BMap.Point(lng, lat);
          map.centerAndZoom(point, 11);
          map.enableScrollWheelZoom(true);
          this.$message.error("未查询到设备轨迹数据");
          this.loading = false;
        } else {
          this.center = res.data.storageDataVos[0];
          this.path = res.data.storageDataVos;
          this.newList = res.data.storageDataVos[0];
          window.localStorage.setItem(
            "goPoints",
            JSON.stringify(res.data.storageDataVos)
          );
          if (this.newList.isRun == "0") {
            this.newList.isRun = "停止";
          } else {
            this.newList.isRun = "运动";
          }
          if (this.newList.locationType == "0") {
            this.newList.locationType = "wifi定位";
          } else {
            this.newList.locationType = "gps定位";
          }
          this.maxTime = res.data.maxTime;
          this.init();
        }
      });
    },
    // 返回上一页
    goback() {
      window.history.go(-1);
    },
    // 搜索按钮
    search() {
      this.lushu.stop();
      this.loading = true;
      this.searchTime.startTime = parseTime(this.searchTime[0]);
      this.searchTime.endTime = parseTime(this.searchTime[1]);
      getStorageTrajectory({
        deviceAddress: this.deviceAddress,
        startTime: this.searchTime.startTime,
        endTime: this.searchTime.endTime
      }).then(res => {
        if (res.data.storageDataVos.length == 0) {
          this.center.lng = this.$route.query.lng;
          this.center.lat = this.$route.query.lat;
          let { lng, lat } = this.center;
          var map = new BMap.Map("maps");
          var point = new BMap.Point(lng, lat);
          map.centerAndZoom(point, 11);
          map.enableScrollWheelZoom(true);
          this.$message.error("未查询到设备轨迹数据");
          this.loading = false;
        } else {
          this.center = res.data.storageDataVos[0];
          this.path = res.data.storageDataVos;
          this.newList = res.data.storageDataVos[0];
          window.localStorage.setItem(
            "goPoints",
            JSON.stringify(res.data.storageDataVos)
          );
          if (this.newList.isRun == "0") {
            this.newList.isRun = "停止";
          } else {
            this.newList.isRun = "运动";
          }
          if (this.newList.locationType == "0") {
            this.newList.locationType = "wifi定位";
          } else {
            this.newList.locationType = "gps定位";
          }
          this.maxTime = res.data.maxTime;
          this.init();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#maps {
  width: 100%;
  height: 100%;
}

.headers {
  position: absolute;
  top: calc(10 / 1080 * 100vw);
  left: calc(20 / 1920 * 100vw);
  span {
    color: #333;
    margin-left: calc(37 / 1920 * 100vw);
    margin-right: calc(20 / 1920 * 100vw);
  }
  .play {
    margin-left: calc(20 / 1920 * 100vw);
    background: #00b0ff;
    color: #fff;
  }
  .suspend {
    margin-left: calc(20 / 1920 * 100vw);
    background: #98c6da;
    color: #fff;
  }
  .continue {
    margin-left: calc(20 / 1920 * 100vw);
    background: #98c6da;
    color: #fff;
  }
  .electronic {
    margin-left: calc(84 / 1920 * 100vw);
    background: #00b0ff;
    color: #fff;
  }
  .active {
    background: #98c6da;
  }
  .isActive {
    background: #00b0ff;
  }
}

::v-deep .el-date-editor {
  background: #fff !important;
  .el-range-input {
    background: #fff !important;
  }
  .el-range-separator {
    background: #fff !important;
  }
}

::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(430 / 1920 * 100vw);
}

::v-deep .MessageBox {
  width: 340px;
  color: #333;
  position: absolute;
  top: 250px;
  right: 30px;
  background: #ffffff;
  border: 2px solid #0e7771;
  border-radius: 8px;
  ul {
    margin: 0px 20px;
    li {
      display: flex;
      margin: 19px 0;
      span:nth-child(2) {
        display: block;
        width: calc(180 / 1920 * 100vw);
        font-weight: bold;
        height: 20px;
        line-height: 21px;
      }
    }
  }
}
.deviceAddress {
  display: inline-block;
  width: calc(160 / 1920 * 100vw);
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
